import type { SVGProps } from 'react'
import { memo } from 'react'

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.734 5.054h1.573v.4c0 .716.248 1.348.645 1.747.382.386.592.901.592 1.444 0 .616-.287 1.163-.712 1.535l-.01.009-.01.008-.247-.317.246.317v.001l-.002.001-.005.004-.016.012-.056.04a3.596 3.596 0 0 1-.191.124c-.146.088-.37.21-.596.26a1.04 1.04 0 0 1-.15.017 31.055 31.055 0 0 1-.646.02c-.332.008-.667.011-.729.011-.148 0-.29-.017-.425-.046l-.017-.004-.016-.005a3.162 3.162 0 0 1-.83-.424l-.015-.01-.013-.011a2.04 2.04 0 0 1-.708-1.541v-.788h1.573v.788a.46.46 0 0 0 .09.274l1.816-.005a.45.45 0 0 0-.039-.603c-.72-.726-1.102-1.747-1.102-2.857v-.4Zm1.355 4.66-2.225.007c.099.053.212.107.32.142.08.016.158.024.236.024a57.74 57.74 0 0 0 1.168-.022 6.446 6.446 0 0 0 .194-.01c.083-.021.195-.074.307-.14Z"
      fill="#222"
    />
    <path fillRule="evenodd" clipRule="evenodd" d="m7.6 0 1.65 5.455h6.6L17.5 0H7.6ZM9.77 1.6l.682 2.255h4.2l.681-2.255H9.77Z" fill="#222" />
    <path
      d="M8.407 21.214v-4.828c0-.102-.062-.2-.172-.273A.767.767 0 0 0 7.82 16a.767.767 0 0 0-.415.113c-.11.073-.172.17-.172.273v4.828c0 .102.062.2.172.273.11.072.26.113.415.113.156 0 .305-.04.415-.113.11-.073.172-.17.172-.273ZM11.34 21.214v-4.828c0-.102-.062-.2-.171-.273a.767.767 0 0 0-.415-.113.767.767 0 0 0-.415.113c-.11.073-.172.17-.172.273v4.828c0 .102.062.2.172.273.11.072.26.113.415.113.155 0 .305-.04.415-.113.11-.073.171-.17.171-.273ZM14.274 21.214v-4.828c0-.102-.062-.2-.172-.273a.767.767 0 0 0-.415-.113.767.767 0 0 0-.415.113c-.11.073-.171.17-.171.273v4.828c0 .102.061.2.171.273.11.072.26.113.415.113.156 0 .305-.04.415-.113.11-.073.172-.17.172-.273ZM16.877 21.214v-4.828c0-.102-.062-.2-.172-.273A.767.767 0 0 0 16.29 16a.767.767 0 0 0-.415.113c-.11.073-.172.17-.172.273v4.828c0 .102.062.2.172.273.11.072.26.113.415.113.155 0 .305-.04.415-.113.11-.073.172-.17.172-.273Z"
      fill="#222"
    />
    <path fillRule="evenodd" clipRule="evenodd" d="M19.187 14.69H4.814v7.71h14.373v-7.71ZM3.2 13.09V24h17.6V13.09H3.2Z" fill="#222" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.918 9.018h2.164l1.86 4.303-1.483.63-1.44-3.333h-.037l-1.44 3.333-1.483-.63 1.86-4.303Z"
      fill="#222"
    />
  </svg>
)

const TerminalIcon = memo(SvgComponent)
export default TerminalIcon
